import { Pagination } from 'react-bootstrap';

export default ({ setPageParams, pageParams }) => {

  const generatePageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= pageParams.totalPages; i++) {

      if (i == 1 || i == pageParams.totalPages || i >= pageParams.page - 3 && i <= pageParams.page + 3) {
        pageNumbers.push(i);
      } else if (pageNumbers[pageNumbers.length - 1] !== '...') {
        pageNumbers.push('...');
      }
    }
    console.log(pageNumbers);
    return pageNumbers;

  };

  generatePageNumbers();

  return (
    <Pagination className="justify-content-end">
      {/* <Pagination.First onClick={() => setPageParams(prevParams => ({ ...prevParams, page: 1 }))} /> */}
      <Pagination.Prev onClick={() => setPageParams(prevParams => ({ ...prevParams, page: Math.max(prevParams.page - 1, 1) }))} />
      {generatePageNumbers().map((number, index) =>
        number === '...' ?
          <Pagination.Ellipsis key={index} /> :
          <Pagination.Item key={index} active={number === pageParams.page} onClick={() => setPageParams(prevParams => ({ ...prevParams, page: number }))}>
            {number}
          </Pagination.Item>
      )}
      <Pagination.Next onClick={() => setPageParams(prevParams => ({ ...prevParams, page: Math.min(prevParams.page + 1, prevParams.totalPages) }))} />
      {/* <Pagination.Last onClick={() => setPageParams(prevParams => ({ ...prevParams, page: prevParams.totalPages }))} /> */}
    </Pagination>
  );
};
