import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../../AppContext'
import { Table, Button, ButtonGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PriceForm from './PriceForm';
import ModelModal from '../Common/ModelModal';
import { FaPencil, FaRegTrashCan, FaCirclePlus } from "react-icons/fa6";


function PricesPage() {
  const { apiClient, setTitle } = useContext(AppContext);
  const [prices, setPrices] = useState([]);
  const [action, setAction] = useState(null); // 'add' or 'edit'
  const [selectedPrice, setSelectedPrice] = useState({});
  const [showModelModal, setShowModelModal] = useState(false);

  useEffect(() => {
    const fetchPrices = async () => {
      try {
        const response = await apiClient.get('prices');
        setPrices(response.data);
      } catch (error) {
        console.error('Failed to fetch prices:', error);
      }
    };

    fetchPrices();
  }, [apiClient]);

  const handleEditClick = (price) => {
    setSelectedPrice(price);
    setAction('edit')
    setShowModelModal(true);
  };

  const handleAddClick = () => {
    setAction('add');
    setShowModelModal(true);
  };

  const handleDeleteClick = async (price) => {
    const confirmed = window.confirm('Are you sure you want to delete price?');
    if (!confirmed) {
      return;
    }
    try {
      await apiClient.delete(`prices/${price.id}`);
      // Update the prices state after successful deletion
      setPrices(prices.filter(p => p.id !== price.id));
    } catch (error) {
      console.error('Failed to delete price:', error);
    }
  };

  const handleAction = async () => {
    try {
      if (action === 'add') {
        const response = await apiClient.post('prices', selectedPrice);
        setPrices([...prices, response.data]); // Add the new price to the prices state
      } else if (action === 'edit') {
        const response = await apiClient.patch(`prices/${selectedPrice.id}`, selectedPrice);
        const updatedPrices = prices.map(p => p.id === selectedPrice.id ? response.data : p); // Update the prices state with the edited price
        setPrices(updatedPrices);
      }
      setShowModelModal(false);
    } catch (error) {
      console.error('Failed to save price:', error);
    }
  };

  return (
    <div className='page'>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Prices</h1>
        <div className="btn-toolbar mb-2 mb-md-0">
          <div className="btn-group me-2">
            <Button onClick={handleAddClick} variant="outline-success">Add</Button>
          </div>
          {/* <button type="button" className="btn btn-sm btn-outline-secondary dropdown-toggle">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-calendar" aria-hidden="true"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
            This week
          </button> */}
        </div>
      </div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Price $</th>
            <th>Comment</th>
            <th>
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {prices.map((price, index) => (
            <tr key={index}>
              <td>{price.startDate?.slice(0,10)}</td>
              <td>{price.endDate?.slice(0,10)}</td>
              <td>{price.value.toLocaleString()}</td>
              <td>{price.comment}</td>
              <td>
                <ButtonGroup>
                  <Button onClick={() => handleEditClick(price)}><FaPencil /></Button>
                  <Button onClick={() => handleDeleteClick(price)} variant="danger"><FaRegTrashCan /></Button>
                </ButtonGroup>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <ModelModal
        show={showModelModal}
        handleAction={handleAction}
        handleClose={() => setShowModelModal(false)}
        action={action}
        modelName="Price"
      >
        <PriceForm price={selectedPrice} setPrice={setSelectedPrice} />
      </ModelModal>
    </div>
  );
}

export default PricesPage;