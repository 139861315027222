import { Table, Button, ButtonGroup, Modal, Form, InputGroup } from 'react-bootstrap';
import React, { useState } from 'react';

function PricesForm({ price, setPrice }) {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPrice({ ...price, [name]: value });
  };

  return (
    <Form>
      <Form.Label>Dates</Form.Label>
      <InputGroup className="mb-3">
        <span className="input-group-text">from </span>
        <Form.Control type="date" name="startDate" value={price.startDate?.slice(0,10)} onChange={handleInputChange} />
        <span className="input-group-text">to </span>
        <Form.Control type="date" name="endDate" value={price.endDate?.slice(0,10)} onChange={handleInputChange} />
      </InputGroup> 
      

      <Form.Label>Price</Form.Label>
      <InputGroup className="mb-3">
        <Form.Control type="text" name="value" value={price.value} onChange={handleInputChange} />
      </InputGroup>

      <Form.Label>Comment</Form.Label>
      <InputGroup className="mb-3">
        <Form.Control type="text" name="comment" value={price.comment} onChange={handleInputChange} />
      </InputGroup>
    </Form>
  );
}

export default PricesForm;