import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const ModelModal = ({ show, handleAction, handleClose, action, modelName, children }) => {
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{`${action} ${modelName}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleAction}>
                    { action == 'add' ? `Add ${modelName}` : `Save ${modelName}` }
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModelModal;