import React, { Fragment, useContext, useState, useEffect, useCallback, useMemo } from 'react';
import AppContext from '../../AppContext';
import ModelModal from '../Common/ModelModal';
import BookingForm from '../Bookings/BookingForm';
import BaseCalendar from "./BaseCalendar";

import moment from 'moment'


function CalendarPage(props) {


  const { apiClient } = useContext(AppContext);
  
  const [bookings, setBookings] = useState([]);
  
  const [calendarRange, setCalendarRange] = useState({
    from: moment().startOf('month').subtract(7, 'days').format('YYYY-MM-DD'),
    till: moment().endOf('month').add(12, 'days').format('YYYY-MM-DD')
  });

  const [showModelModal, setShowModelModal] = useState(false);

  const [action, setAction] = useState(null);
  const [selectedBooking, setSelectedBooking] = useState({});


  const handleAction = async () => {
    try {
      if (action === 'add') {
        const response = await apiClient.post('bookings', selectedBooking);
        setBookings([...bookings, response.data]);
      } else if (action === 'edit') {
        const response = await apiClient.patch(`bookings/${selectedBooking.id}`, selectedBooking);
        const updatedBookings = bookings.map(booking => booking.id === selectedBooking.id ? {
          title: selectedBooking.client.name,
          start: new Date(new Date(selectedBooking.startDate).setHours(14, 0, 0, 0)),
          end: new Date(new Date(selectedBooking.endDate).setHours(12, 0, 0, 0)),
          ...response.data
        } : booking);

        setBookings(updatedBookings);
      }
      setShowModelModal(false);
    } catch (error) {
      console.error('Failed to save booking:', error);
    }
  };

  const handleSelectEvent = (event) => {
    setAction('edit');
    setSelectedBooking(bookings.find(b => event.id === b.id));
    setShowModelModal(true);
  };

  const handleRangeChange = (range) => {
    setCalendarRange({
      from: moment(range.start).format('YYYY-MM-DD'),
      till: moment(range.end).format('YYYY-MM-DD')
    });
  };

  const fetchBookings = async () => {
    try {
      const response = await apiClient.get('bookings', { 
        params: { 
          paginate: false,
          from: calendarRange.from,
          till: calendarRange.till,
          filter: JSON.stringify({"status":["confirmed"]})
        } 
      });
      setBookings(response.data.bookings.map(booking => ({
        title: `[${booking.code}] ${booking.client.name}`,
        start: new Date(new Date(booking.startDate).setHours(14, 0, 0, 0)),
        end: new Date(new Date(booking.endDate).setHours(12, 0, 0, 0)),
        ...booking
      })));
    } catch (error) {
      console.error('Failed to fetch bookings:', error);
    }
  };

  useEffect(() => {
    fetchBookings();
  }, [calendarRange]);


  return (
    <div className='page py-4'>
      <BaseCalendar events={bookings} selectEventCallback={handleSelectEvent} rangeChangeCallback={handleRangeChange} />
      <ModelModal
        show={showModelModal}
        handleAction={handleAction}
        handleClose={() => setShowModelModal(false)}
        action={action}
        modelName="Booking"
      >
        <BookingForm booking={selectedBooking} setBooking={setSelectedBooking} />
      </ModelModal>
    </div>

  );
}

export default CalendarPage;
