import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../AppContext";
import {
  Table,
  Button,
  ButtonGroup,
  Dropdown,
  ToggleButton,
  InputGroup,
  Form,
} from "react-bootstrap";
import Pagination from "./Pagination.js";
import BookingForm from "./BookingForm";
import ModelModal from "../Common/ModelModal";
import { FaPencil, FaRegTrashCan } from "react-icons/fa6";
import { FaTrashRestore } from "react-icons/fa";
import { FaSortUp, FaSortDown, FaFilter } from "react-icons/fa";

function BookingsPage() {
  const { apiClient } = useContext(AppContext);
  const [bookings, setBookings] = useState([]);
  const [showModelModal, setShowModelModal] = useState(false);

  const [action, setAction] = useState(null);
  const [selectedBooking, setSelectedBooking] = useState({});

  const [pageParams, setPageParams] = useState({
    showDeleted: false,
    pageSize: 20,
    page: 1,
    sortField: "createdAt",
    sortOrder: "DESC",
    filter: JSON.stringify({}),
    totalPages: 1,
    currentPage: 1,
    from: null,
    till: null,
  });

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const response = await apiClient.get("bookings", {
          params: pageParams,
        });
        setBookings(response.data.bookings);
        const newPageParams = {
          ...pageParams,
          totalPages: response.data.totalPages,
          currentPage: response.data.currentPage,
        };
        if (JSON.stringify(newPageParams) !== JSON.stringify(pageParams)) {
          setPageParams(newPageParams);
        }
      } catch (error) {
        console.error("Failed to fetch bookings:", error);
      }
    };

    fetchBookings();
  }, [pageParams]);

  const handleEditClick = (booking) => {
    setSelectedBooking(booking);
    setAction("edit");
    setShowModelModal(true);
  };

  const handleAddClick = () => {
    setAction("add");
    setShowModelModal(true);
  };

  const handleDeleteClick = async (booking) => {
    try {
      await apiClient.delete(`bookings/${booking.id}`);
      setBookings(
        bookings.map((b) =>
          b.id === booking.id ? { ...booking, deletedAt: true } : b
        )
      );
    } catch (error) {
      console.error("Failed to delete price:", error);
    }
  };

  const handleRestoreClick = async (booking) => {
    try {
      const response = await apiClient.patch(`bookings/${booking.id}`, {
        deletedAt: null,
      });
      const updatedBookings = bookings.map((b) =>
        b.id === booking.id ? { ...booking, deletedAt: null } : b
      );
      setBookings(updatedBookings);
    } catch (error) {
      console.error("Failed to restore booking:", error);
    }
  };

  const handleAction = async () => {
    try {
      if (action === "add") {
        const response = await apiClient.post("bookings", selectedBooking);
        setBookings([...bookings, response.data]);
      } else if (action === "edit") {
        const response = await apiClient.patch(
          `bookings/${selectedBooking.id}`,
          selectedBooking
        );
        const updatedBookings = bookings.map((b) =>
          b.id === selectedBooking.id ? response.data : b
        );
        setBookings(updatedBookings);
      }
      setShowModelModal(false);
    } catch (error) {
      console.error("Failed to save booking:", error);
    }
  };

  return (
    <div className="page">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Bookings</h1>
        <div className="btn-toolbar mb-2 mb-md-0">
          <div className="btn-group me-2">
            <Button onClick={handleAddClick} variant="outline-success">
              Add
            </Button>
            <ToggleButton
              variant={pageParams.showDeleted ? "warning" : "outline-warning"}
              id="tbg-btn-1"
              value={pageParams.showDeleted}
              onClick={() =>
                setPageParams((prevParams) => ({
                  ...prevParams,
                  showDeleted: !pageParams.showDeleted,
                }))
              }
            >
              show deleted
            </ToggleButton>
          </div>
        </div>
      </div>

      {/* Date filter */}
      <InputGroup className="justify-content-end mb-3">
        <span className="input-group-text">from </span>
        <Form.Control
          type="date"
          name="startDate"
          value={pageParams.from}
          onChange={(e) =>
            setPageParams((prevParams) => ({
              ...prevParams,
              from: e.target.value,
            }))
          }
        />
        <span className="input-group-text">to </span>
        <Form.Control
          type="date"
          name="endDate"
          value={pageParams.till}
          onChange={(e) =>
            setPageParams((prevParams) => ({
              ...prevParams,
              till: e.target.value,
            }))
          }
        />
      </InputGroup>

      <Pagination pageParams={pageParams} setPageParams={setPageParams} />

      {/* Main Table */}
      <Table striped bordered hover>
        <thead>
          <tr>
            <th
              style={{ width: "10%", overflow: "auto" }}
              onClick={() =>
                setPageParams((prevParams) => ({
                  ...prevParams,
                  sortField: "createdAt",
                  sortOrder:
                    prevParams.sortField === "createdAt" &&
                    prevParams.sortOrder === "ASC"
                      ? "DESC"
                      : "ASC",
                }))
              }
            >
              Date & code{" "}
              {pageParams.sortField === "createdAt" &&
                (pageParams.sortOrder === "ASC" ? (
                  <FaSortUp />
                ) : (
                  <FaSortDown />
                ))}
            </th>
            <th style={{ width: "10%", overflow: "auto" }}>Name</th>
            <th style={{ width: "20%", overflow: "auto" }}>Contacts</th>
            <th
              style={{ width: "10%", overflow: "auto" }}
              onClick={() =>
                setPageParams((prevParams) => ({
                  ...prevParams,
                  sortField: "startDate",
                  sortOrder:
                    prevParams.sortField === "startDate" &&
                    prevParams.sortOrder === "ASC"
                      ? "DESC"
                      : "ASC",
                }))
              }
            >
              Booking Dates{" "}
              {pageParams.sortField === "startDate" &&
                (pageParams.sortOrder === "ASC" ? (
                  <FaSortUp />
                ) : (
                  <FaSortDown />
                ))}
            </th>
            <th
              style={{ width: "10%", overflow: "auto" }}
              onClick={() =>
                setPageParams((prevParams) => ({
                  ...prevParams,
                  sortField: "price",
                  sortOrder:
                    prevParams.sortField === "price" &&
                    prevParams.sortOrder === "ASC"
                      ? "DESC"
                      : "ASC",
                }))
              }
            >
              Price ${" "}
              {pageParams.sortField === "price" &&
                (pageParams.sortOrder === "ASC" ? (
                  <FaSortUp />
                ) : (
                  <FaSortDown />
                ))}
            </th>
            <th style={{ width: "25%", overflow: "auto" }}>Comment</th>
            <th style={{ width: "25%", overflow: "auto" }}>AI feed</th>
            <th>
              Status
              <Dropdown>
                <Dropdown.Toggle as={FaFilter}></Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() =>
                      setPageParams((prevParams) => ({
                        ...prevParams,
                        filter: JSON.stringify({ status: ["new"] }),
                      }))
                    }
                  >
                    New
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      setPageParams((prevParams) => ({
                        ...prevParams,
                        filter: JSON.stringify({ status: ["pending"] }),
                      }))
                    }
                  >
                    Pending
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      setPageParams((prevParams) => ({
                        ...prevParams,
                        filter: JSON.stringify({ status: ["confirmed"] }),
                      }))
                    }
                  >
                    Confirmed
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      setPageParams((prevParams) => ({
                        ...prevParams,
                        filter: JSON.stringify({ status: ["failed"] }),
                      }))
                    }
                  >
                    Failed
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item
                    onClick={() =>
                      setPageParams((prevParams) => ({
                        ...prevParams,
                        filter: null,
                      }))
                    }
                  >
                    Clear
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </th>
            <th
              onClick={() =>
                setPageParams((prevParams) => ({
                  ...prevParams,
                  sortField: "source",
                  sortOrder:
                    prevParams.sortField === "source" &&
                    prevParams.sortOrder === "ASC"
                      ? "DESC"
                      : "ASC",
                }))
              }
            >
              Source{" "}
              {pageParams.sortField === "source" &&
                (pageParams.sortOrder === "ASC" ? (
                  <FaSortUp />
                ) : (
                  <FaSortDown />
                ))}
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {bookings.map((booking, index) => (
            <tr key={index} className={booking.deletedAt ? "deleted" : ""}>
              <td>
                {booking.createdAt.slice(0, 10)}
                <br />
                {booking.code}
              </td>
              <td>{booking.client.name}</td>
              <td>
                {booking.client.email}
                <br />
                {booking.client.phone}
                <br />
                {booking.metadata?.kommo?.id && (
                  <>
                    <a
                      href={`https://ecommerceprivatejetvillacom.kommo.com/leads/detail/${booking.metadata.kommo.id}`}
                      target="_blank"
                    >
                      Kommo
                    </a>
                    <br />
                  </>
                )}

                {booking.metadata?.ref && `source: ${booking.metadata.ref}`}
              </td>
              <td>
                {booking.startDate ? booking.startDate?.slice(0, 10) : ""}
                <br />
                {booking.endDate ? booking.endDate?.slice(0, 10) : ""}
              </td>
              <td>{booking.price.toLocaleString()}</td>
              <td>{booking.comment}</td>
              <td>
                Name: {booking.client.name}
                <br />
                Email: {booking.client.email}
                <br />
                Phone: {booking.client.phone}
                <br />
                Check in date:{" "}
                {booking.startDate ? booking.startDate?.slice(0, 10) : ""}
                <br />
                Check out date:{" "}
                {booking.endDate ? booking.endDate?.slice(0, 10) : ""}
                <br />
                Additional request: {booking.comment}
              </td>
              <td>{booking.status}</td>
              <td>{booking.source}</td>
              <td>
                <ButtonGroup>
                  <Button
                    onClick={() => handleEditClick(booking)}
                    disabled={booking.deletedAt}
                  >
                    <FaPencil />
                  </Button>
                  {booking.deletedAt ? (
                    <Button
                      variant="success"
                      onClick={() => handleRestoreClick(booking)}
                    >
                      <FaTrashRestore />
                    </Button>
                  ) : booking.status !== "confirmed" ? (
                    <Button
                      variant="danger"
                      onClick={() => handleDeleteClick(booking)}
                    >
                      <FaRegTrashCan />
                    </Button>
                  ) : null}
                </ButtonGroup>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Pagination pageParams={pageParams} setPageParams={setPageParams} />

      <ModelModal
        show={showModelModal}
        handleAction={handleAction}
        handleClose={() => setShowModelModal(false)}
        action={action}
        modelName="Booking"
      >
        <BookingForm
          booking={selectedBooking}
          setBooking={setSelectedBooking}
        />
      </ModelModal>
    </div>
  );
}

export default BookingsPage;
