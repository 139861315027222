import React, { Fragment, useMemo, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {
  Calendar,
  momentLocalizer,
} from 'react-big-calendar'
const events = [];

const mLocalizer = momentLocalizer(moment)

const ColoredDateCellWrapper = ({ children }) =>
  React.cloneElement(React.Children.only(children), {
    style: {
      backgroundColor: 'lightblue',
    },
  })


export default function Basic({
  localizer = mLocalizer,
  selectEventCallback,
  rangeChangeCallback,
  events,
  ...props
}) {

  const [myEvents, setEvents] = useState(events)

  const { components, defaultDate} = useMemo(
    () => ({
      components: {
        timeSlotWrapper: ColoredDateCellWrapper,
      },
      defaultDate: new Date()
    }),
    []
  )

  const handleSelectSlot = useCallback(
    ({ start, end }) => {
      // if(selectEventCallback) selectEventCallback();
    },
    [setEvents]
  )

  const handleRangeChange = useCallback((range) => {
    if (rangeChangeCallback) rangeChangeCallback(range);
  }, [rangeChangeCallback]);

  const handleSelectEvent = useCallback((event) => {
    if (selectEventCallback) selectEventCallback(event);
  }
  )

  return (
    <Fragment>
      <div className="height600" {...props}>
        <Calendar
          components={components}
          defaultDate={defaultDate}
          events={events}
          localizer={localizer}
          showMultiDayTimes
          step={60}
          onSelectEvent={handleSelectEvent}
          onSelectSlot={handleSelectSlot}
          onRangeChange={handleRangeChange}
          views = {{
            month: true,
          }}
        selectable
        />
      </div>
    </Fragment>
  )
}
Basic.propTypes = {}