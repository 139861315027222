// App.js
import React, { useState } from 'react';
import { createBrowserRouter, RouterProvider, redirect } from 'react-router-dom';
import { Container, Navbar, Nav } from 'react-bootstrap';
import LoginPage from './components/LoginPage';
import CalendarPage from './components/Calendar/CalendarPage';
import BookingsPage from './components/Bookings/BookingsPage';
import PricesPage from './components/Prices/PricesPage';
import MainLayout from './MainLayout';
import AppContext from './AppContext';
import apiClient from './ApiClient';

function isLoggedIn() {
  return localStorage.getItem('token') !== null;
}

const router = createBrowserRouter([
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/",
    element: <MainLayout />,
    loader: async () => {
      if (!isLoggedIn()) {
        return redirect("/login");
      }
      return null;
    },
    children: [
      {
        path: "calendar",
        element: <CalendarPage />,
      },
      {
        path: "bookings",
        element: <BookingsPage />,
      },
      {
        path: "prices",
        element: <PricesPage />,
      },
    ],
  },
]);

function App() {
  const [userData, setUserData] = useState({});
  const [title, setTitle] = useState(''); // Add this line

  const updateUserData = (newData) => {
    setUserData(prevData => ({ ...prevData, ...newData }));
  };

  return (
    <AppContext.Provider value={{apiClient, title, setTitle}}>
      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>
    </AppContext.Provider>
  );
}

export default App;