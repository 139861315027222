import { useContext, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import AppContext from '../AppContext';
import Logo from '../logo.svg';


function LoginPage() {
  const { apiClient } = useContext(AppContext);
  const emailRef = useRef();
  const passwordRef = useRef();
  const navigate = useNavigate();

  async function login(email, password) {
    try {
      const response = await apiClient.post('/auth', { email, password });
      const token = response.data.token;
      apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      localStorage.setItem('token', token);
      navigate('/');
    } catch (error) {
      alert(`Error: ${error.message}`);
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const email = emailRef.current.value;
    const password = passwordRef.current.value;
    await login(email, password);
  }


  return (
    <div id="login-wrapper">
      <main className="form-signin">
        <form onSubmit={handleSubmit}>
          <img className="mb-2" src={Logo} alt="" width="72" />
          <h1 className="h3 mb-3 fw-normal">Please sign in</h1>

          <div className="form-floating">
            <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" ref={emailRef} />
            <label>Email address</label>
          </div>
          <div className="form-floating">
            <input type="password" className="form-control" id="floatingPassword" placeholder="Password" ref={passwordRef} />
            <label>Password</label>
          </div>

          <div className="checkbox mb-3">
            <label>
              <input type="checkbox" value="remember-me" /> Remember me
            </label>
          </div>
          <button className="w-100 btn btn-lg btn-primary" type="submit">Sign in</button>
          <p className="mt-5 mb-3 text-muted">© 2017–2021</p>
        </form>
      </main>
    </div>
  )
};

export default LoginPage;
