import Logo from './logo.svg';
import {useContext} from 'react'
import AppContext from './AppContext'
import { Navbar, Nav } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function Header() {
  const { apiClient, title } = useContext(AppContext);
  const navigate = useNavigate();
  const signOut = () => {
    localStorage.clear();
    delete apiClient.defaults.headers.common['Authorization'];
    navigate('/login');
  };

  return (
    <header className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
      <a className="navbar-brand col-md-2 col-lg-1 me-0 px-3" href="#">
        <img src={Logo} alt="Logo" />
      </a>
      <button className="navbar-toggler position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      {/* <h1>{title}</h1> */}
      <div className="navbar-nav">
        <div className="nav-item text-nowrap">
          <a className="nav-link px-3" href="" onClick={signOut}>Sign out</a>
        </div>
      </div>
    </header>
  );
}

export default Header;
