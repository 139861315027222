import {
  Table,
  Button,
  ButtonGroup,
  Modal,
  Form,
  InputGroup,
} from "react-bootstrap";
import React, { useState, useEffect } from "react";

function BookingForm({ booking, setBooking }) {
  useEffect(() => {
    setBooking(booking);
  }, [booking]);

  const handleInputChange = (event) => {
    if (event.target.name.startsWith("client")) {
      const clientField = event.target.name.split("_")[1];
      return setBooking({
        ...booking,
        client: {
          ...booking.client,
          [clientField]: event.target.value,
        },
      });
    }
    setBooking({
      ...booking,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <Form>
      <Form.Label>Dates</Form.Label>
      <InputGroup className="mb-3">
        <span className="input-group-text">from </span>
        <Form.Control
          type="date"
          name="startDate"
          value={booking.startDate?.slice(0, 10)}
          onChange={handleInputChange}
        />
        <span className="input-group-text">to </span>
        <Form.Control
          type="date"
          name="endDate"
          value={booking.endDate?.slice(0, 10)}
          onChange={handleInputChange}
        />
      </InputGroup>

      <Form.Group className="mb-3">
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          name="client_name"
          defaultValue={booking?.client?.name}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          name="client_email"
          defaultValue={booking?.client?.email}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Phone</Form.Label>
        <Form.Control
          type="tel"
          name="client_phone"
          defaultValue={booking?.client?.phone}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Comment</Form.Label>
        <Form.Control
          as="textarea"
          name="comment"
          defaultValue={booking?.comment}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Status</Form.Label>
        <Form.Control
          as="select"
          name="status"
          defaultValue={booking?.status}
          onChange={handleInputChange}
        >
          <option value="new">New</option>
          <option value="pending">Pending</option>
          <option value="failed">Failed</option>
          <option value="confirmed">Confirmed</option>
          <option value="hold">Hold</option>
        </Form.Control>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Source</Form.Label>
        <Form.Control
          as="select"
          name="source"
          defaultValue={booking?.source}
          onChange={handleInputChange}
        >
          <option value="native">Native</option>
          <option value="farsite">Farsite</option>
        </Form.Control>
      </Form.Group>
    </Form>
  );
}

export default BookingForm;
