import React from 'react';
import { Outlet } from "react-router-dom";
import Sidebar from './Sidebar';
import Header from './Header';

function MainLayout(){
  return (
    <>
    <Header />
    <div className="container-fluid">
      <Sidebar />
      <main className="col-md-10 ms-sm-auto col-lg-11 px-md-4">
        <Outlet />
      </main>
    </div>
  </>);
  
};

export default MainLayout;
